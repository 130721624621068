import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Footer() {
  return (
    <footer className="container-fluid py-3 text-center border-top footer-bottom">
      <p className="mb-0">© 2024 TruPrompt Generator All Rights Reserved.</p>
    </footer>
  );
}
