import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setPromptSource, useGetAuthPromptsQuery, useGetPromptsQuery } from "../store/slices/truPromptSlice";
import TruncateText from './TruncateText';
import { useDispatch } from 'react-redux';
import { setPromptId } from '../store/slices/truPromptSlice';
import { useAllUsersQuery } from '../store/slices/authSlice';
import userLogo from '../assets/circle-user-solid.svg';

const GetPrompts = ({ auth }) => {

    // Fetch all users
    const { data: users } = useAllUsersQuery();


    const dispatch = useDispatch();

    const { data: prompts = [] } = useGetAuthPromptsQuery();
    const { data: allprompts = [] } = useGetPromptsQuery();




    const handlePromptSearch = (e, promptId) => {
        e.preventDefault();
        dispatch(setPromptId(promptId));
        dispatch(setPromptSource('singlePrompt')); // Set source to singlePrompt
    }

    const getUserProfileImage = (userId) => {
        const userProfile = users.filter?.((user) => userId === user._id)
        return userProfile[0].profileImage === '404' ? userLogo : userProfile[0].profileImage;
    }

    const getUserName = (userId) => {
        const userProfile = users.filter?.((user) => userId === user._id);
        return userProfile[0].name;
    }


    // Assuming prompts is an array of objects as shown in the image
    const sortedPrompts = [...(auth ? prompts : allprompts)].sort((a, b) => {
        if (auth) {
            // Sort by updatedAt in descending order if auth is true
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        } else {
            // Sort by mostUsedPromptCount in descending order if auth is false
            return b.mostUsedPromptCount - a.mostUsedPromptCount;
        }
    });

    return (
        <div className="tab">
            {auth && <h5 className="most-used-heading">Recent Saved Prompts</h5>}
            {!auth && <h5 className="most-used-heading">Most Used Prompts</h5>}
            <ul className="list-group ">
                {sortedPrompts.length > 0 ? (
                    sortedPrompts.map((prompt, index) => (
                        <li key={index} className="list-group-item d-flex align-items-center justify-content-between">
                            <div className='d-flex align-items-center'>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id={`tooltip-${index}`}>{getUserName(prompt.userId)}</Tooltip>}
                                >
                                    <img
                                        src={getUserProfileImage(prompt.userId)}
                                        alt='Profile'
                                        width={32}
                                        height={32}
                                        className="rounded-circle"
                                        style={{ marginRight: '5px' }}

                                    />
                                </OverlayTrigger>
                                <button
                                    className="btn btn-link p-0 list-item-left tgpt-list-butn"
                                    onClick={(e) => handlePromptSearch(e, prompt._id)}
                                    title={prompt.userPrompt}
                                >
                                    <TruncateText text={prompt.userPrompt} wordLimit={7} />
                                </button>
                            </div>
                            {!auth && prompt.mostUsedPromptCount !== 0 && (<span className="badge rounded-pill count-searches">{prompt.mostUsedPromptCount}</span>)}
                        </li>
                    ))
                ) : (
                    <li className="list-group-item d-flex align-items-center justify-content-between">
                        Search the Prompt...
                    </li>
                )}
            </ul>
        </div>
    );


}

export default GetPrompts