import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { usePostPromptMutation } from '../store/slices/truPromptSlice';
import Swal from 'sweetalert2';

const SaveModal = ({ show, selectedPrompt, setShowSaveDialog }) => {
    const [postPrompt] = usePostPromptMutation();
    const [saveCategory, setSaveCategory] = useState("SEO");
    const handleClose = () => {
        setShowSaveDialog(false);
    }
    // console.log(selectedPrompt);

    const savePrompt = () => {
        const newPrompt = {
            category: saveCategory,
            userPrompt: selectedPrompt.userPrompt,
            prompt: selectedPrompt.prompt,
            optimizer: selectedPrompt.optimizer,
        };

        postPrompt(newPrompt)
            .unwrap()
            .then((response) => {
                // console.log('Prompt saved successfully:', response);
                Swal.fire({ // Show success alert
                    title: 'Success!',
                    text: 'Prompt saved successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    handleClose(); // Close the modal after the user confirms the alert
                });
            })
            .catch((error) => {
                console.error('Error saving prompt:', error);
                Swal.fire({ // Show error alert
                    title: 'Error!',
                    text: 'Error saving prompt. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
    };

    const handleRadioChange = (e) => {
        setSaveCategory(e.target.value);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Save this prompt </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to save this prompt to the: {saveCategory}?</p>
                {["SEO", "Design", "Communication", "Web Development", "Lead Generation", "Lifecycle Marketing", "Business Development"].map((category) => (
                    <div key={category} className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="saveCategory"
                            id={category}
                            value={category}
                            checked={saveCategory === category}
                            onChange={handleRadioChange}
                        />
                        <label className="form-check-label" htmlFor={category}>
                            {category}
                        </label>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={savePrompt}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SaveModal;