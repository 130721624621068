// src/store/slices/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Replace with your API key
const GROQ_API_KEY = "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";

// Async action for fetching data from GROQ API
export const fetchApiData = createAsyncThunk(
    'api/fetchApiData',
    async (prompt, { rejectWithValue }) => {
        try {
            const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${GROQ_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model: "llama-3.2-1b-preview",
                    messages: [{ role: "user", content: prompt }],
                    temperature: 0.7,
                    max_tokens: 1024,
                }),
            });
            if (!response.ok) {
                throw new Error(`Error ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const apiSlice = createSlice({
    name: 'api',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApiData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchApiData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchApiData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default apiSlice.reducer;
