// openAIUtils.js
import Swal from 'sweetalert2';
import { setPromptSource } from '../store/slices/truPromptSlice';
import { useDispatch, useSelector } from 'react-redux';
import OpenAI from "openai";
import { useState } from 'react';

// Function to update OpenAI results and store them in local storage
export const updateOpenAIResults = (setOpenAIResults, newResults) => {
    setOpenAIResults(prevResults => {
        const currentResults = Array.isArray(prevResults) ? prevResults : [];

        const updatedResults = [
            ...currentResults,
            ...newResults.map(result => ({
                ...result,
                // expiration: new Date(Date.now() + 5 * 1000).toISOString() // Set expiration for 5 seconds (example)
                expiration: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // Set expiration for 30 days
            }))
        ];

        // Store in local storage
        const dataToStore = {
            results: updatedResults
        };
        localStorage.setItem('openAIResults', JSON.stringify(dataToStore));

        return updatedResults; // Return updated results for state
    });
};

// Function to handle prompt search
export const handlePromptSearch = async (
    e,
    prompt,
    setPrompt,
    setLoading,
    setOpenAIResults,
    selectedOptions,
    setSelectedOptions,
    dispatch
) => {

    e.preventDefault();

    // Initialize OpenAI with your API key and allow browser usage
    const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API, // Ensure your API key is set correctly
        dangerouslyAllowBrowser: true, // Allow usage in a browser-like environment
    });

    const optionsList = [
        { name: "SEO", param: "As an SEO expert, focus on tasks like on-page optimization (meta tags, content structure), off-page strategies (backlink building, outreach), and performing a comprehensive site audit to identify issues and opportunities" },
        { name: "Design", param: "As a designer, consider aspects such as user experience, visual aesthetics, brand consistency, and effective communication through design elements" },
        { name: "Communication", param: "As a communication specialist, focus on effective messaging, audience engagement, and strategies for clear and impactful communication across various channels" },
        { name: "Web Development", param: "As a web developer, consider aspects such as responsive design, performance optimization, accessibility, and modern web technologies" },
        { name: "Lead Generation", param: "As a lead generation expert, focus on strategies to attract and convert potential customers into qualified leads for the business" },
        { name: "Lifecycle Marketing", param: "As a lifecycle marketing specialist, focus on strategies for customer acquisition, retention, and growth across various stages of the customer journey" },
        { name: "Business Development", param: "As a business development professional, focus on strategies for growth, partnerships, market expansion, and improving overall business performance" },
    ];

    if (prompt) {
        const searchPrompt = `Generate only a powerful and descriptive prompt for the following scenario: Write A Prompt For ${prompt}. Do not provide a solution, only a well-crafted prompt that can guide someone else to create a response.`.trim();

        setLoading(true);


        const lowerPrompt = searchPrompt.toLowerCase();
        let autoSelectedOptions = selectedOptions.length > 0 ? selectedOptions : ["SEO"];

        // Update options based on keywords in the prompt
        if (lowerPrompt.includes('seo') && !autoSelectedOptions.includes("SEO")) {
            autoSelectedOptions.push("SEO");
        }
        if ((lowerPrompt.includes('web') || lowerPrompt.includes('development')) && !autoSelectedOptions.includes("Web Development")) {
            autoSelectedOptions.push("Web Development");
        }
        if ((lowerPrompt.includes('lifecycle') || lowerPrompt.includes('marketing')) && !autoSelectedOptions.includes("Lifecycle Marketing")) {
            autoSelectedOptions.push("Lifecycle Marketing");
        }

        setSelectedOptions(autoSelectedOptions);

        // Ensure autoSelectedOptions is an array
        autoSelectedOptions = Array.isArray(autoSelectedOptions) && autoSelectedOptions.length > 0 ? autoSelectedOptions : ["SEO"];

        // Combine selected options into a single string
        const autoSelectedOptionsString = autoSelectedOptions.join(', ');

        try {
            // Combine selected options into a single prompt
            const combinedOptions = autoSelectedOptions.map(optionName => {
                const option = optionsList.find(opt => opt.name === optionName);
                return option ? option.param : '';
            }).join(' and ');

            // Make API call to OpenAI
            const stream = await openai.chat.completions.create({
                model: "gpt-4o-mini",
                messages: [
                    {
                        role: "system",
                        content: `As a multi-expert, consider the following aspects: ${combinedOptions}. Your task is to generate a prompt ONLY, which will guide the next steps. Do not provide solutions or answers. THE RESPONSE MUST FOLLOW THIS FORMAT:

              Optimizer: [- Provide multiple key context points or goals as bullet points.]
              |
              Prompt: [Generate a well-crafted and detailed prompt that is impactful].`
                    },
                    {
                        role: "user",
                        content: searchPrompt
                    }
                ],
                temperature: 0.7,
            });

            const content = stream.choices[0]?.message?.content || "";
            const [optimizer, generatedPrompt] = content.split('|').map(part => part ? part.replace(/(Optimizer: |Prompt: )/, '').trim() : "");

            const searchId = Date.now();

            const structuredResponse = {
                category: autoSelectedOptionsString,
                userPrompt: prompt,
                optimizer: optimizer,
                prompt: generatedPrompt,
                id: searchId,
            };

            // Update the OpenAI results using the utility function
            updateOpenAIResults(setOpenAIResults, [structuredResponse]);

            // Reset selectedOptions and prompt to empty
            setSelectedOptions([]);
            setPrompt("");

            // Dispatch an action (if applicable)
            dispatch(setPromptSource('openAIPrompts'));
        } catch (error) {
            console.error('Error fetching from OpenAI API:', error);
        } finally {
            setLoading(false);
        }
    } else {
        Swal.fire({
            title: 'Error!',
            text: 'Please provide the prompt...',
            icon: 'error',
            confirmButtonText: 'OK'
        });
    }
};
