import React, { useState, useRef } from 'react';
// import { openai } from '../path/to/openai'; // Adjust the import based on your project structure

import { useDispatch } from 'react-redux';
// import { setPromptSource } from '../store/slices/truPromptSlice';
import { handlePromptSearch } from './openAIUtils';


const HandlePrompt = ({
    setLoading,
    selectedOptions,
    setSelectedOptions,
    openAIResults,
    setOpenAIResults,
}) => {


    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [prompt, setPrompt] = useState("");


    const optionsList = [
        { name: "SEO", param: "As an SEO expert, focus on tasks like on-page optimization (meta tags, content structure), off-page strategies (backlink building, outreach), and performing a comprehensive site audit to identify issues and opportunities" },
        { name: "Design", param: "As a designer, consider aspects such as user experience, visual aesthetics, brand consistency, and effective communication through design elements" },
        { name: "Communication", param: "As a communication specialist, focus on effective messaging, audience engagement, and strategies for clear and impactful communication across various channels" },
        { name: "Web Development", param: "As a web developer, consider aspects such as responsive design, performance optimization, accessibility, and modern web technologies" },
        { name: "Lead Generation", param: "As a lead generation expert, focus on strategies to attract and convert potential customers into qualified leads for the business" },
        { name: "Lifecycle Marketing", param: "As a lifecycle marketing specialist, focus on strategies for customer acquisition, retention, and growth across various stages of the customer journey" },
        { name: "Business Development", param: "As a business development professional, focus on strategies for growth, partnerships, market expansion, and improving overall business performance" },
    ];



    const updateOpenAIResults = (newResults) => {
        setOpenAIResults(prevResults => {
            // Ensure prevResults is an array
            const currentResults = Array.isArray(prevResults) ? prevResults : [];

            // Append new results with expiration date
            const updatedResults = [
                ...currentResults,
                ...newResults.map(result => ({
                    ...result,
                    // expiration: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000 + (5.5 * 60 * 60 * 1000)).toISOString() // Set expiration for 30 days in IST
                    expiration: new Date(Date.now() + 5 * 1000).toISOString()


                }))
            ];

            // Store in local storage
            const dataToStore = {
                results: updatedResults
            };

            localStorage.setItem('openAIResults', JSON.stringify(dataToStore));
            return updatedResults; // Return updated results for state
        });
    };


    const toggleOption = (optionName) => {
        setSelectedOptions(prev =>
            prev.includes(optionName)
                ? prev.filter(name => name !== optionName)
                : [...prev, optionName]
        );
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    // document.removeEventListener('mousedown', handleClickOutside);


    const getDropdownText = () => {
        if (selectedOptions.length === 0) return "Select options";
        if (selectedOptions.length === 1) return selectedOptions[0];
        return `${selectedOptions[0]} (+${selectedOptions.length - 1})`;
    };


    const onSubmit = async (e) => {
        handlePromptSearch(e, prompt, setPrompt, setLoading, setOpenAIResults, selectedOptions, setSelectedOptions, dispatch)
    }



    return (
        <form onSubmit={onSubmit} className="d-flex align-items-center p-3 custom-search-bar">
            <div className="dropdown me-2" style={{ minWidth: "220px" }} ref={dropdownRef}>
                <button
                    className="btn btn-secondary dropdown-toggle w-100 drop-button select-dropdown"
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    aria-expanded={isDropdownOpen}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0.375rem 0.75rem',
                        backgroundColor: '#1e293b',
                        color: 'white',
                        border: 'none'
                    }}
                >
                    <span style={{ marginRight: 'auto' }}>{getDropdownText()}</span>
                    <i className="fas fa-chevron-down" style={{ marginLeft: '0.5rem' }}></i>
                </button>
                {isDropdownOpen && (
                    <ul className="dropdown-menu show" style={{ width: "100%" }}>
                        {optionsList.map((option) => (
                            <li key={option.name}>
                                <div className="dropdown-item">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            role="button"
                                            type="checkbox"
                                            id={`check-${option.name}`}
                                            checked={selectedOptions.includes(option.name)}
                                            onChange={() => toggleOption(option.name)}
                                        />
                                        <label className="form-check-label" htmlFor={`check-${option.name}`} role="button">
                                            {option.name}
                                        </label>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <input
                className="form-control me-2 input-bx"
                value={`${prompt}`}
                placeholder="Type your idea or scenario here to generate a powerful prompt..."
                onChange={(e) => setPrompt(e.target.value)}
                required
                style={{ flex: 1 }}
            />
            <button className="btn btn-primary Search-button" type="submit">
                <img
                    src="/images/icons8-ai-48.png"
                    alt="AI Search"
                    style={{ width: '24px', height: '24px' }}
                />
            </button>
        </form>
    );
};

export default HandlePrompt;