import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';


const token = Cookies.get('token');


const createRequest = (url) => ({
    url,
    headers: {
        'Authorization': `Bearer ${token}` // Ensure the token is valid
    }
});


export const truPromptApi = createApi({
    reducerPath: 'truPromptApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api` }),
    tagTypes: ['Prompts'],
    endpoints: (builder) => ({
        // Fetch all Prompts
        GetPrompts: builder.query({
            query: () => ({
                method: 'GET',
                ...createRequest(`/prompts/`),
            }),
            transformResponse: (response) => response.allPrompts,
            providesTags: ['Prompts'],
        }),
        getAuthPrompts: builder.query({
            query: () => ({
                method: 'GET',
                ...createRequest(`/prompts/auth/verifiedUserPrompts/`),
            }),
            transformResponse: (response) => response.allPrompts,
            providesTags: ['Prompts'],
        }),
        // Fetch Single Prompt
        getSinglePrompt: builder.query({
            // query: (id) => `/prompts/${id}`,
            query: (id) => ({
                method: 'GET',
                ...createRequest(`/prompts/${id}`),
            }),
            transformResponse: (response) => response.prompt,
            providesTags: (result, error, id) => [{ type: 'Prompts', id }]
        }),
        // Post a new Prompt
        postPrompt: builder.mutation({
            query: (newPrompt) => ({
                method: 'POST',
                body: newPrompt,
                ...createRequest(`/prompts/auth/verifiedUserPrompts/`),
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Prompts'],
        }),
        updatePromptCount: builder.mutation({
            query: (id) => ({
                method: 'PATCH',
                ...createRequest(`/prompts/updatePromptCount/${id}`),
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Prompts', id }],
        }),
        updatePrompt: builder.mutation({
            query: ({ id, ...updatedPrompt }) => ({
                url: `/prompts/${id}`,
                method: 'PATCH',
                body: updatedPrompt,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Prompts', id }],
        })
    }),
});

const promptSlice = createSlice({
    name: 'prompt',
    initialState: {
        promptId: null,
    },
    reducers: {
        setPromptId: (state, action) => {
            state.promptId = action.payload;
        },
    },
});

export const selectPromptId = (state) => state.prompt.promptId;

export const { setPromptId } = promptSlice.actions;
export const promptReducer = promptSlice.reducer;

const promptResultsSlice = createSlice({
    name: 'promptResults',
    initialState: {
        promptSource: 'openAIPrompts', // Default source
    },
    reducers: {
        setPromptSource(state, action) {
            state.promptSource = action.payload; // Set the source to either 'openAI' or 'single'
        },
        clearResults(state) {
            state.promptSource = ''; // Reset to default
        },
    },
});

export const { setPromptSource, clearResults } = promptResultsSlice.actions;

export const selectPromptSource = (state) => state.promptResults.promptSource;
export const promptResultsReducer = promptResultsSlice.reducer;


export const {
    useGetAuthPromptsQuery,
    useGetPromptsQuery,
    useGetSinglePromptQuery,
    usePostPromptMutation,
    useUpdatePromptMutation,
    useUpdatePromptCountMutation
} = truPromptApi;