import React, { useState } from 'react'
import SaveModal from './SaveModal';
import Swal from 'sweetalert2';
import fontAnimation from '../assets/Animation.gif'
import { useUpdatePromptCountMutation } from '../store/slices/truPromptSlice';
import { handlePromptSearch } from './openAIUtils';
import { useDispatch } from 'react-redux';
import aiAnimation from '../assets/AI.gif'

const PromptResults = ({ prompts, setPrompt, promptFromDB, loading, setLoading, setOpenAIResults, setSelectedOptions }) => {

    const dispatch = useDispatch();

    const [mostUsedPromptCount] = useUpdatePromptCountMutation();
    // const promptData = prompts && prompts.prompt ? prompts.prompt : null;
    // console.log(prompts);

    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    // const [show, setShow] = useState(false);


    const handleShow = (result) => {
        setSelectedPrompt(result); // Set the selected prompt data
        setShowSaveDialog(true); // Show the save dialog
    };

    const copyToClipboard = async (result) => {
        const textToCopy = result

        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    Swal.fire({ // Show success alert
                        title: 'Success!',
                        text: 'Prompt copied to the Clipboard successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                })
                .catch(err => {
                    Swal.fire({ // Show error alert
                        title: 'Error!',
                        text: 'Faied to Copy. Please copy manually',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                });
        } else {
            Swal.fire({ // Show error alert
                title: 'Error!',
                text: 'Faied to Copy. Please copy manually',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }

        // increment the mostUsedPromptCounttry
        if (result._id) {
            try {
                await mostUsedPromptCount(result._id).unwrap()
            } catch (error) {
                Swal.fire({ // Show error alert
                    title: 'Error!',
                    text: 'Unable to increment the most Used Prompt Count. Error on the server level',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                // console.log(error);
            }

        }

    };

    const regeneratePrompt = async (e, result) => {
        let prompt = result.userPrompt;
        let selectedOptions = result.category.includes(',') ? result.category.split(',') : [result.category];
        handlePromptSearch(e, prompt, setPrompt, setLoading, setOpenAIResults, selectedOptions, setSelectedOptions, dispatch)

    };

    if (loading) {
        return <>
            <img src={fontAnimation} alt="Loading Text" />
        </>
    }


    return (
        <>
            {promptFromDB ? (
                // When promptFromDB is true, display the singlePrompt
                <div className="result-container mb-4">
                    <div className='mb-5 mt-1'>
                        <h6 className="bg-dark text-white p-2 rounded-top mb-0 text-center">Prompt From the Database</h6>
                    </div>
                    <h5>
                        <i className="fa fa-tags" aria-hidden="true"></i> Prompt for the Function: {prompts.category}
                    </h5>
                    <p className="text-muted">User Prompt: {prompts.userPrompt}</p>
                    <div className="mb-4">
                        <h6 className="bg-dark text-white p-2 rounded-top mb-0">Optimizer</h6>
                        <div className="bg-light p-3 rounded-bottom">
                            <pre className="mb-0" style={{ whiteSpace: 'pre-line' }}>{prompts.optimizer}</pre>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h6 className="bg-dark text-white p-2 rounded-top mb-0">Prompt</h6>
                        <div className="bg-light p-3 rounded-bottom">
                            <pre className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{prompts.prompt}</pre>
                        </div>
                    </div>
                    <div className="mt-3 button-copy">
                        <button className="btn btn-primary me-2" onClick={() => handleShow(prompts)}>
                            Save
                        </button>
                        <button className="btn btn-secondary me-2" onClick={() => copyToClipboard(prompts)}>
                            Copy
                        </button>
                        <button
                            className={`btn btn-info regen}`}
                            onClick={(e) => regeneratePrompt(e, prompts)}
                        >Regenerate
                        </button>
                    </div>
                </div>
            ) : (
                // When promptFromDB is false, display the openAIResults

                prompts
                    .sort((a, b) => b.id - a.id)
                    .map((result) => (
                        <div key={result.id} className="result-container mb-4">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className='w-75'>
                                    <i className="fa fa-tags" aria-hidden="true"></i> Prompt for the Function: {result.category}
                                </h5>
                                <div className='d-flex align-items-center w-25'>
                                    <img src={aiAnimation} alt="AI" width={80} />
                                    <div>
                                        <p className='m-0'>Powered by</p>
                                        <p className='m-0'>Open AI</p>

                                    </div>
                                </div>

                            </div>
                            <p className="text-muted">User Prompt: {result.userPrompt}</p>
                            <div className="mb-4">
                                <h6 className="bg-dark text-white p-2 rounded-top mb-0">Optimizer</h6>
                                <div className="bg-light p-3 rounded-bottom">
                                    <pre className="mb-0" style={{ whiteSpace: 'pre-line' }}>{result.optimizer}</pre>
                                </div>
                            </div>
                            <div className="mb-4">
                                <h6 className="bg-dark text-white p-2 rounded-top mb-0">Prompt</h6>
                                <div className="bg-light p-3 rounded-bottom">
                                    <pre className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{result.prompt}</pre>
                                </div>
                            </div>
                            <div className="mt-3 button-copy">
                                <button className="btn btn-primary me-2" onClick={() => handleShow(result)}>
                                    Save
                                </button>
                                <button className="btn btn-secondary me-2" onClick={() => copyToClipboard(result.prompt)}>
                                    Copy
                                </button>
                                <button
                                    className={`btn btn-info regen}`}
                                    onClick={(e) => regeneratePrompt(e, result)}
                                >Regenerate
                                </button>
                            </div>
                        </div >
                    ))
            )}

            <SaveModal selectedPrompt={selectedPrompt} handleShow={handleShow} show={showSaveDialog} setShowSaveDialog={setShowSaveDialog} />
        </>
    )
}

export default PromptResults