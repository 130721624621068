import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from './Header';
import Footer from './Footer';
import { selectPromptId, selectPromptSource, useGetSinglePromptQuery } from "./store/slices/truPromptSlice";
import { useMicrosoftLoginQuery } from "./store/slices/authSlice";
import fontAnimation from './assets/Animation.gif'
import GetPrompts from "./components/GetPrompts";
import HandlePrompt from "./components/HandlePrompt";
import PromptResults from "./components/PromptResults";
import { useSelector } from 'react-redux';

export default function TruPromptGenerator() {


  // Microsoft SSO
  const { data: userData, error, isLoading } = useMicrosoftLoginQuery();

  const promptId = useSelector(selectPromptId);
  // console.log("Prompt Id: ", promptId);

  const { data: singlePrompt, } = useGetSinglePromptQuery(promptId, {
    skip: !promptId,
  });

  const Results = useSelector(selectPromptSource);
  // console.log("Results", Results);


  const [selectedOptions, setSelectedOptions] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState("");


  // to fetch the prompts stoed in the local storage 
  const [openAIResults, setOpenAIResults] = useState(() => {
    // Fetch data from local storage and parse it
    const storedData = localStorage.getItem('openAIResults');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData.results || []; // Return results or an empty array if not found
    }
    return []; // Default to an empty array if no data is found
  });

  useEffect(() => {
    const cleanExpiredLocalStorage = () => {
      // Retrieve the data from localStorage
      const data = localStorage.getItem('openAIResults');
      if (data) {
        const parsedData = JSON.parse(data);

        // Filter out expired items
        const now = new Date().getTime();
        const updatedResults = parsedData.results.filter(item => {
          const expirationDate = new Date(item.expiration).getTime();
          return expirationDate > now; // Keep only non-expired items
        });

        if (updatedResults.length !== parsedData.results.length) {
          // If any items were removed, update localStorage
          localStorage.setItem(
            'openAIResults',
            JSON.stringify({ results: updatedResults })
          );
        }
      }
    };

    // Run the cleanup function when the component mounts
    cleanExpiredLocalStorage();
  }, []); // Empty dependency array to run only once when App loads

  if (isLoading) { // Check for loading state
    return (
      <div className="container d-flex flex-column gap-3 mt-5 justify-content-center align-items-center">
        <h2>Loading...</h2>
        <img src={fontAnimation} alt="Loading Text" />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="container vh-100 gap-5 d-flex flex-column justify-content-center align-items-center" >
        <h2>Please log in using Microsoft SSO</h2>
        <button className="btn btn-primary" onClick={() => window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/users/auth/microsoft`}>
          Login with Microsoft
        </button>
      </div >
    );
  }

  return (
    <>
      <Header userData={userData} />
      <div className="container-fluid my-4 banner-container position-relative banner-section" style={{ padding: "0", height: "450px" }}>
        <img
          src="/images/background.jpg"
          className="img-fluid w-100 h-100 header-logo"
          alt="Banner"
          style={{ objectFit: "cover" }}
        />
        <div className="form-overlay position-absolute top-50 start-50 p-3 translate-middle bg-white shadow rounded align-items-center form-container" style={{ width: '65%' }}>
          <h6 className="heading-container">TruPrompt Generator: {userData.name}</h6>

          <HandlePrompt
            setLoading={setLoading}
            setCurrentPrompt={setCurrentPrompt}
            selectedOptions={selectedOptions}
            openAIResults={openAIResults}
            setSelectedOptions={setSelectedOptions}
            setOpenAIResults={setOpenAIResults}
          />


          {loading && <div className="text-center mt-2 loading-text">Loading...</div>}
        </div>
      </div >

      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="row result-sec">
          <div className="col-md-3 left-side-section">
            <div className="left-sticky-sec d-flex flex-column gap-5">
              <GetPrompts auth={false} />
              <GetPrompts auth={true} />
            </div>
          </div>

          <div className="col-md-9 right-result-site">
            <PromptResults
              loading={loading}
              prompts={(Results === 'openAIPrompts') ? openAIResults : singlePrompt || []}
              setPrompt={setPrompt}
              promptFromDB={(Results === 'singlePrompt') ? true : false}
              setLoading={setLoading}
              setOpenAIResults={setOpenAIResults}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}

            />
            {/* <PromptResults prompts={(openAIResults ? openAIResults : singlePrompt)} /> */}

          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}